import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";
import laptopImg from "../../Assets/about.png";

function About() {
  return (
    <Container fluid className="about-section">
      <Particle />
      <Container>
        <Row style={{ justifyContent: "center", padding: "10px" }}>
          <Col
            md={7}
            style={{
              justifyContent: "center",
              paddingTop: "30px",
              paddingBottom: "50px",
            }}
          >
            <h1 style={{ fontSize: "2.1em", paddingBottom: "20px" }}>
              <strong className="blue">Choice Coin</strong>
            </h1>
            <p>
            Choice Coin is a decentralized autonomous network (DAN). The focus for the DAN is building open source software for decentralized governance. Choice Coin started at the 2021 MIT Bitcoin Hackathon, the projects co-creators won the top prize with their project, Algorand Autonomous, a primitve DAO with novel governance technology. Since that time, the project has partnered with several institutional and decentralized organizations to build one of the largest open source governance projects in the world, with over 100 global contributors.            
            </p>
            
          </Col>
          <Col
            md={5}
            style={{ paddingTop: "120px", paddingBottom: "50px" }}
            className="about-img"
          >
            <img src={laptopImg} alt="about" className="img-fluid" />
          </Col>
        </Row>
        <h1 className="project-heading">
          <strong className="purple">FAQ </strong>
        </h1>

        

        <h1 className="project-heading">
          <strong className="purple"></strong>  What blockchain is Choice Coin building on?
        </h1>

        <p>
        Choice Coin works across Bitcoin, Ethereum, Solana, and Algorand.
        </p>

        <h1 className="project-heading">
         <strong className="purple">  </strong> What has Choice Coin invented? 
        </h1>

        <p>
        Choice Coin has invented quantum secure voting technology, automated compliance software, and valid smart contracts.

        </p>
        <h1 className="project-heading">
         <strong className="purple">  </strong> How can I earn Choice?
        </h1>

        <p>
        The best way to earn Choice is to get involved with the project via social channels.

        </p>
      </Container>
    </Container>
  );
}

export default About;
