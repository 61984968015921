import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCards0 from "./ProjectCards0";
import ProjectCards1 from "./ProjectCards1";
import ProjectCards2 from "./ProjectCards2";
import ProjectCards3 from "./ProjectCards3";
import ProjectCards4 from "./ProjectCards4";
import ProjectCards5 from "./ProjectCards5";
import ProjectCards6 from "./ProjectCards6";
import ProjectCards7 from "./ProjectCards7";
import ProjectCards8 from "./ProjectCards8";
import Particle from "../Particle";
import img1 from "../../Assets/Projects/img1.png";
import img2 from "../../Assets/Projects/img2.png";
import img3 from "../../Assets/Projects/img3.png";
import img4 from "../../Assets/Projects/img4.png";
import img5 from "../../Assets/Projects/img5.png";
import img6 from "../../Assets/Projects/img6.png";
import img7 from "../../Assets/Projects/img7.png";
import img8 from "../../Assets/Projects/img8.png";
import img9 from "../../Assets/Projects/img9.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          Our <strong className="purple">Work </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects we are working on.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCards0
              imgPath={img1}
              isBlog={false}
              title="Voting"
              description="Choice Coin pioneers decentralized and quantum-secure voting technology, ensuring the integrity and confidentiality of electoral processes in an era of advancing quantum computing capabilities. By leveraging cutting-edge cryptographic techniques and decentralized governance principles, Choice Coin's voting system offers unprecedented security and resilience, safeguarding the democratic principles of transparency and fairness in decision-making within decentralized networks."
              ghLink="https://papers.ssrn.com/sol3/papers.cfm?abstract_id=4111692"
              ghLink1="https://github.com/ChoiceCoin/Voting"
              ghLink2="https://patents.google.com/patent/US20230086612A1"
              ghLink3="https://patents.google.com/patent/US20230198773A1"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCards1
              imgPath={img2}
              isBlog={false}
              title="Artificial Intelligence"
              description="Choice Coin's AI technology represents a pioneering fusion of Machine Learning (ML) and blockchain, harnessing the potential of both fields to enhance decentralized governance systems. By leveraging ML algorithms within blockchain networks, Choice Coin enables intelligent decision-making processes, facilitates data-driven insights, and optimizes governance mechanisms for greater efficiency and effectiveness. "
              ghLink="https://papers.ssrn.com/sol3/papers.cfm?abstract_id=3939208"
              ghLink1="https://github.com/ChoiceCoin/DeFi"
              ghLink2="https://patents.google.com/patent/US20230086612A1"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCards2
              imgPath={img3}
              isBlog={false}
              title="Compliance"
              description="Choice Coin's compliance software integrates advanced AI capabilities with comprehensive data analytics to revolutionize compliance processes within decentralized networks. By harnessing the power of data-driven insights and AI automation, Choice Coin empowers organizations to streamline compliance operations, mitigate risks, and ensure adherence to regulatory standards in a dynamic and ever-evolving landscape. "
              ghLink="https://papers.ssrn.com/sol3/papers.cfm?abstract_id=4189147"
              ghLink1="https://github.com/ChoiceCoin/Compliance"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCards3
              imgPath={img4}
              isBlog={false}
              title="Smart Contracts"
              description="Choice Coin's focus on creating legally valid contracts on the blockchain is paramount to its mission. By merging smart contract technology with legal expertise, Choice Coin provides users with a reliable platform to generate contracts that are both enforceable and compliant with relevant regulations. "
              ghLink="https://papers.ssrn.com/sol3/papers.cfm?abstract_id=3887719"
              ghLink1="https://github.com/ChoiceCoin/Smart_Contracts"
              ghLink2="https://patents.google.com/patent/US20230014140A1"           
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCards4
              imgPath={img5}
              isBlog={false}
              title="Quantum"
              description="Choice Coin's quantum technology represents the cutting edge of innovation, where machine learning meets the power of quantum computing. By harnessing the potential of quantum algorithms, Choice Coin aims to revolutionize data processing and analysis, paving the way for unprecedented advancements in AI-driven decision-making within decentralized networks. "
              ghLink="https://github.com/ChoiceCoin/Quantum"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCards6
              imgPath={img6}
              isBlog={false}
              title="Decentralized Decisions"
              description="Decentralized Decisions is the cornerstone of governance within the Choice Coin network, providing a user-friendly platform for voting and decision-making. Through its decentralized architecture, the app enables all network participants to contribute to the governance process, ensuring that decisions are made collectively and transparently. "
              ghLink="https://www.decentralized-decisions.app/"
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCards7
              imgPath={img7}
              isBlog={false}
              title="White Papers"
              description="Choice Coin's white papers serve as the foundational blueprints for the network's evolution, with distinct documents delineating the principles and innovations of both v1 and v2. These comprehensive papers outline the technical specifications, governance models, and vision for the network's development, providing invaluable guidance for stakeholders, developers, and enthusiasts alike."
              ghLink="https://github.com/ChoiceCoin/White_Paper"
              ghLink1="https://github.com/ChoiceCoin/v2/blob/main/WhitePaper/v2.pdf"
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCards8
              imgPath={img8}
              isBlog={false}
              title="Security"
              description="Security is paramount in Choice Coin's development, underpinning every aspect of its ecosystem. With decentralized governance and sensitive transactions at its core, Choice Coin prioritizes robust security measures to safeguard user assets, data integrity, and network stability. "
              ghLink="https://github.com/ChoiceCoin/Voting_DApp/blob/main/SecurityAudit/SecurityAudit.pdf"
              ghLink1="https://github.com/ChoiceCoin/v2/blob/main/Security/v2_Security_Report.pdf"
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCards5
              imgPath={img9}
              isBlog={false}
              title="Converter"
              description="Choice Coin's converter represents a pivotal component in its ecosystem, facilitating seamless conversions between diverse blockchains like Algorand, Solana, and Ethereum, although it's currently under development and not yet operational. Once deployed, this converter will bridge the gap between different blockchain networks, enhancing interoperability and accessibility for Choice Coin users and contributors. "
              ghLink="https://github.com/ChoiceCoin/converter"
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
