import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/homeLogo.png";
import Tilt from "react-parallax-tilt";


function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              Choice Coin's <span className="blue"> Purpose </span> 
            </h1>
            <p>
            Choice Coin's primary purpose is to advance Open Source Software (OSS) for decentralized governance, with a particular emphasis on refining voting protocols and ensuring regulatory compliance. Through its dedication to these areas, Choice Coin seeks to establish robust frameworks that empower communities to democratically govern themselves while adhering to legal and regulatory standards.            
            </p>

          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              <img src={myImg} className="img-fluid" alt="avatar" />
            </Tilt>
          </Col>

        </Row>
        
      </Container>
    </Container>
  );
}
export default Home2;
